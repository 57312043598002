<template>
  <div class="page-contact-school">
    <div class="my-page">
      <van-search
        v-model="userName"
        placeholder="搜索联系人"
        clearable
        background="transparent"
        @search="onSearch"
        @cancel="onCancel"
      />
      <div class="list-box">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="getDataList"
            offset="10"
          >
            <div class="my-card" v-for="(item, i) in dataList" :key="i">
              <div class="left">
                <div class="role">
                  <img
                    class="pic"
                    :src="
                      item.ProfilePicture ? item.ProfilePicture : defaultImg
                    "
                    alt=""
                    @error="errorImg"
                  />
                </div>
                <div class="info">
                  <div class="info-head">
                    <span class="name">{{ item.UserName }}</span>
                    -
                    <span class="phone">{{ item.CellPhone }}</span>
                  </div>
                  <div class="info-foot">
                    <span v-if="item.ClassName" class="class">{{
                      item.ClassName
                    }}</span>
                    <span class="label">{{ item.RoleName }}</span>
                  </div>
                </div>
              </div>
              <div class="right" @click="callPhone(item.CellPhone)"></div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
  </div>
</template>

<script>
import defaultImg from '@/assets/icon/ic_role0.png'
import { List, PullRefresh, Search } from 'vant'
export default {
  components: {
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Search.name]: Search
  },
  data () {
    return {
      userType: window.localStorage.getItem('UserType'),
      defaultImg: defaultImg,
      loading: false,
      finished: false, // false
      refreshing: false,
      dataList: [],
      userName: '',
      page: 0, // 页码
      limit: 10 // 每页条数
    }
  },
  mounted () {
    // 空白时候自动触发
    // this.getDataList()
  },
  methods: {
    getDataList () {
      this.page++
      this.getList()
    },
    getList () {
      const that = this
      let url
      if (this.userType === '3') {
        url = '/api/AddressBook/SchoolForParentList'
      } else if (this.userType === '2') {
        url = '/api/AddressBook/SchoolList'
      }
      that.$axios
        .get(url, {
          userName: this.userName,
          page: this.page,
          limit: this.limit
        })
        .then(res => {
          if (res.code === 200) {
            that.refreshing = false
            that.loading = false
            that.dataList = that.dataList.concat(res.data)
            if (that.dataList.length >= res.count) {
              that.finished = true
            }
          } else {
            that.$toast.fail(res.msg || '操作失败')
          }
        })
    },
    // 下拉刷新
    onRefresh () {
      this.page = 0
      // 清空列表数据
      this.finished = false
      // 将 loading 设置为 true，表示处于加载状态
      // this.loading = true
      this.dataList = []
      this.getDataList()
    },
    onSearch (val) {
      this.page = 0
      // 清空列表数据
      this.dataList = []
      this.getDataList()
    },
    onCancel () {
      console.log('取消')
    },
    callPhone (tel) {
      if (tel) {
        window.location.href = 'tel://' + Number(tel)
      }
    },
    errorImg () {
      const img = event.srcElement
      img.src = this.defaultImg
      img.onerror = null
    }
  }
}
</script>
<style lang="less" scoped>
@import './index.less';
</style>
