<template>
  <div class="page-unread">
    <div class="tab-box">
      <div
        class="tab-item"
        v-for="(num, index) in tabs"
        :key="index"
        :class="{ active: index === tabNum }"
        @click="tabClick(index)"
      >
        {{ num }}
      </div>
    </div>
    <school v-if="tabNum === 0" />
    <class v-if="tabNum === 1" />
    <!-- 菜单栏 -->
    <NavBar v-bind:active="2" />
  </div>
</template>

<script>
import School from './School/index'
import Class from './Class/index'
import NavBar from '@/components/NavBar'
export default {
  components: {
    School,
    Class,
    NavBar
  },
  data () {
    return {
      userType: window.localStorage.getItem('UserType'),
      tabs: ['园内'],
      tabNum: 0
    }
  },
  mounted () {
    if (this.userType === '2') {
      this.tabs.push('家长')
    }
  },
  methods: {
    tabClick (index) {
      this.tabNum = index
    }
  }
}
</script>
<style lang="less" scoped>
@import './index.less';
</style>
